import React from 'react';
import parse from 'html-react-parser';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

export function Checklist({ texts, className }) {
    return (
        <ul className={className ? className : ''}>
            {texts.map((text, index) => (
                <li className="flex items-start mb-2" key={index}>
                    <CheckCircleIcon className="w-6 mr-1" />
                    <span className="w-full">{parse(text)}</span>
                </li>
            ))}
        </ul>
    );
}
