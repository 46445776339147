import React from 'react';

import Layout, {
    bgImageDefault,
    Col2,
    Col3,
    PageHeaderA,
    PageSection,
    Ul,
} from '../components/layout';
import { PageSubtitle, PageText, PageTitle } from '../components/typography';
import { ContactSection } from '../components/contact';
import { Tag, TagList } from '../components/tags';
import { makeSectionId } from '../components/jumpboxlink';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { Box } from '../components/box';
import { Checklist } from '../components/checklist';
import { ServiceJumpTable } from '../components/servicejumptable';
import Seo from '../components/seo';

function CM({ data, location }) {
    const { t } = useTranslation();
    const overview = [
        {
            title: t('compliance_title_1', '(Security) Compliance Management'),
            desc: [
                t(
                    'compliance_desc_1',
                    'Die Plattform ermöglicht eine effiziente Umsetzung und langfristige Erfüllung von Compliance-Anforderungen, wie NIS2 und die Implementierung von Managementsystemen wie ISO/IEC 27001.'
                ),
                t(
                    'compliance_desc_2',
                    'Sie ermöglicht eine integrative Umsetzung und kombinierte Erfüllung von Compliance Anforderungen aus unterschiedlichen Quellen.'
                ),
            ],
            labels: [],
        },
        {
            title: t('compliance_title_2', 'Datenschutz-Compliance'),
            desc: [
                t(
                    'compliance_desc_3',
                    'Die Einführung von Managementsystemen wie ISO/IEC 27701 ermöglicht eine effiziente Umsetzung und langfristige Einhaltung von Datenschutz-Anforderungen.'
                ),
            ],
            labels: [],
        },
    ];

    return (
        <Layout
            location={location}
            bgImage={bgImageDefault.platform}
            headerContent={
                <PageHeaderA
                    title="Compliance Management"
                    text={[
                        t(
                            'subtitle',
                            'Mit den wachsenden Compliance-Anforderungen Schritt halten. '
                        ),
                    ]}
                    image={
                        <StaticImage
                            src="../images/headers/srm.jpg"
                            alt="Security-Rating"
                            loading="eager"
                            objectFit="cover"
                            placeholder="none"
                            className="aspect-video"
                            width={500}
                        />
                    }
                />
            }
        >
            <Seo title={t('title', 'Compliance Management')} />
            <PageSection size="big">
                <PageSubtitle className="mt-4">
                    <Trans i18nKey="intro_title">
                        Effektive Sicherstellung der Compliance zu Gesetzen und
                        Vorschriften.
                    </Trans>
                </PageSubtitle>
                <PageText className="mt-4">
                    <Trans i18nKey="intro_2">
                        Das condignum Compliance Management Modul hilft dabei,
                        mit den ständig wachsenden Anforderungen Schritt zu
                        halten und bietet eine Lösung, um die Einhaltung
                        branchenüblicher Bestimmungen und Normen
                        sicherzustellen. Durch intelligente Verkettung von
                        Plattforminhalten und detaillierte Reporting-Funktionen
                        wird nicht nur die Einhaltung von Vorschriften, wie
                        ISO/IEC 27001 erleichtert, sondern auch Transparenz und
                        Nachvollziehbarkeit ermöglicht.
                    </Trans>
                </PageText>
            </PageSection>

            <ServiceJumpTable items={overview} type="compliance" />

            <PageSection size="big">
                <PageTitle className="mt-4">
                    <Trans i18nKey="compliance_why">
                        Warum Compliance Management?
                    </Trans>
                </PageTitle>
                <PageSubtitle className="mt-2">
                    <Trans i18nKey="compliance_why_subtitle_1">
                        Echtzeit-Compliance-Überwachung:
                    </Trans>
                </PageSubtitle>
                <Checklist
                    className="mt-6 ml-2 max-w-3xl"
                    texts={[
                        t(
                            'compliance_why_check_1',
                            'Durch die kontinuierliche Echtzeit-Überwachung des Compliance-Status und benutzerdefinierte Auswertungen können potenzielle Risiken vermieden werden, was die Möglichkeit bietet, sofortige Maßnahmen zu ergreifen.'
                        ),
                    ]}
                />
                <PageSubtitle className="mt-4">
                    <Trans i18nKey="compliance_why_subtitle_2">
                        Effiziente Ressourcennutzung:
                    </Trans>
                </PageSubtitle>
                <Checklist
                    className="mt-6 ml-2 max-w-3xl"
                    texts={[
                        t(
                            'compliance_why_check_2',
                            'Durch die Nutzung der condignum Plattform können Compliance-Aufgaben effizienter erledigen werden.'
                        ),
                        t(
                            'compliance_why_check_3',
                            'Automatisierte Prozesse reduzieren den manuellen Arbeitsaufwand, sodass Ihre Ressourcen effektiver eingesetzt werden können.'
                        ),
                    ]}
                />
                <PageSubtitle className="mt-4">
                    <Trans i18nKey="compliance_why_subtitle_3">
                        Präzise Dokumentation:
                    </Trans>
                </PageSubtitle>
                <Checklist
                    className="mt-6 ml-2 max-w-3xl"
                    texts={[
                        t(
                            'compliance_why_check_4',
                            'Die condignum Plattform ermöglicht eine präzise Dokumentation aller Compliance-Aktivitäten. Dies ist nicht nur wichtig für die Einhaltung gesetzlicher Vorschriften, sondern auch für interne und externe Audits.'
                        ),
                    ]}
                />
            </PageSection>

            <LabeledSection title={overview[0].title} isDark>
                <PageText>
                    <Trans i18nKey="compliance_desc_1">
                        Die Plattform ermöglicht eine effiziente Umsetzung und
                        langfristige Erfüllung von Compliance-Anforderungen, wie
                        NIS2 und die Implementierung von Managementsystemen wie
                        ISO/IEC 27001.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="compliance_desc_2">
                        Sie ermöglicht eine integrative Umsetzung und
                        kombinierte Erfüllung von Compliance Anforderungen aus
                        unterschiedlichen Quellen.
                    </Trans>
                </PageText>
            </LabeledSection>
            <LabeledSection title={overview[1].title}>
                <PageText>
                    <Trans i18nKey="compliance_desc_3">
                        Die Einführung von Managementsystemen wie ISO/IEC 27701
                        ermöglicht eine effiziente Umsetzung und langfristige
                        Einhaltung von Datenschutz-Anforderungen.
                    </Trans>
                </PageText>
            </LabeledSection>

            <ContactSection isDark={false} />
        </Layout>
    );
}

function LabeledSection({
    children,
    title,
    subtitle,
    labels = [],
    isDark = false,
}) {
    return (
        <PageSection
            size="big"
            id={makeSectionId(title)}
            isDark={isDark}
            hasPadding={true}
        >
            <PageTitle className="md:flex relative">
                {title}
                <TagList
                    as="small"
                    labels={labels}
                    className="hidden md:flex ml-2"
                >
                    {labels.map(label => (
                        <Tag key={label} as="span">
                            {label}
                        </Tag>
                    ))}
                </TagList>
            </PageTitle>
            <PageSubtitle>{subtitle}</PageSubtitle>
            <TagList labels={labels} className="-mt-5 mb-8 md:hidden">
                {labels.map(label => (
                    <Tag key={label}>{label}</Tag>
                ))}
            </TagList>
            {children}
        </PageSection>
    );
}

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["compliance-management"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default CM;
